/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, {useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {setResult} from '../../reducers/chatActions';

import PhotoSmall from '../../images/photo-small.png'
import './AnswerBubble.css';
import { getBadge } from '../../utils/helpers';

type AnswerBubbleProps = {
    fullpage: boolean;
    isMobile: boolean;
    image: boolean;
    data?: any;
}

const AnswerBubble: React.FC<AnswerBubbleProps> = ({children, fullpage, isMobile, image, data}) => {
    const dispatch = useDispatch();
    const [answer, setAnswer] = useState({});
    const [hasBadge, setHasBadge] = useState(false);

    useEffect(()=>{
        if (data.props && data.props.data) {
            setHasBadge(true);
            setAnswer(data.props.data);
        }
    }, [data.props])
    
    const handleClick = useCallback(()=>{
        if (!data) return;
        dispatch(setResult(data));
    }, [data, dispatch])

    const answerBubbleStyle = {
        position: 'relative',
        cursor:  data?'pointer':'initial',
        width: isMobile? '95%' : fullpage ? '366px' : '70%',
        margin: isMobile? '8px' : '8px auto',
        transition: 'width 0.5s',
        marginTop: hasBadge? '42px' : '8px',
    } as React.CSSProperties;

    return (
        <div data-testid="answerBubble" className={'answerBubble'} style={answerBubbleStyle} onClick={handleClick}>
            {hasBadge && <div className="region-badge-container-mobile">
                <img src={getBadge(answer)} className="region-badge-mobile" alt="Answer Source"/>
            </div>}
            {image && <img src={PhotoSmall} alt="Answer Bubble" className="answerBubbleImage" />}
            <div>{children}</div>
        </div>
    );
}

export default AnswerBubble;
