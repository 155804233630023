/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {Dropdown, Form} from 'react-bootstrap';

import { setRegions } from '../../reducers/actions';
import {globalSources} from '../../utils/helpers';
import requests from '../../utils/requests'

import Doctors from '../Doctors/Doctors';
import Lsis from '../Lsis/Lsis';
import Resources from '../Resources/Resources';
import DrugInteraction from '../DrugInteraction/DrugInteraction';
import DrugDosage from '../DrugDosage/DrugDosage';
import HelpPage from '../HelpPage/HelpPage';
import Region from '../Region/Region';

import Logo from '../../images/svg/Logo-light.svg';
import './Result.css';
import { REGIONAL_ENABLED, SOURCES_ENABLED } from '../../utils/constants';

type ResultProps = {
    OnLogOut: any;
}

const ners = {
    ANATOMY: 'Anatomy/‌Body organ',
    MEDICAL_CONDITION: 'Medical Condition',
    MEDICATION: 'Drug/Medication',
    PROTECTED_HEALTH_INFORMATION: 'PHI',
    TEST_TREATMENT_PROCEDURE: 'Test/Procedure',
    TIME_EXPRESSION: 'Time',
}

const Result: React.FC<ResultProps> = ({OnLogOut}) => {
    const username = useSelector<any,any>(state=> state.auth.username);
    const result = useSelector<any,any>(state=> state.result);
    const global_regions = useSelector<any,any>(state=> state.global_regions);
    const dispatch = useDispatch();

    let resourcesClass = "resourcesMenuItem"
    let content = null;
    if (result) {
        switch(result.type) {
            case 'lsi':
                content = (<Lsis data={result} />);
                break;
            case 'drs':
                content = (<Doctors data={result} />)
                break;
            case 'resources':
                content = (<Resources />);
                resourcesClass += " active"
                break;
            case 'drugInteraction':
                content = (<DrugInteraction data={result} />)
                break;
            case 'drugDosage':
                content = (<DrugDosage data={result} />)
                break;
            case 'help':
                content = (<HelpPage tagClickHandler={result.tagClickHandler} />)
                break;
            default:
                content = null
        }
    }

    const setGlobalRegions = async (key: string, event: any) => {
        let updated_regions = Object.assign({}, global_regions);
        updated_regions[key] = event.target.checked;
        dispatch(setRegions(updated_regions))
        await requests.updateUserProfile({'global_regions': updated_regions});
    }

    const dropdownItems = Object.keys(globalSources).map((key, i) =>
        <div key={i} className="source-item">
            <div className="source-info">
                <img src={globalSources[key].logo} alt={globalSources[key].name} className="source-logo"/>
                <span>{globalSources[key].name}</span>
            </div>

            <Form.Check 
                type="switch"
                id={key}
                label=""
                checked={global_regions[key]}
                onChange={(e: any) => setGlobalRegions(key, e)}
            />
        </div>
    );

    return (
        <div className="resultArea">
            <div className="resultHeader">
                <img src={Logo} className="resultLogo" alt="Logo" />
                <Dropdown alignRight={true} className={resourcesClass}>
                    <Dropdown.Toggle id="dropdown-user" as="span"><div className="circleUser"></div> <span className="userName">{username}</span> <i className="fa fa-caret-down"></i></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={OnLogOut}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {SOURCES_ENABLED &&
                    <Dropdown alignRight={true} className={resourcesClass}>
                        <Dropdown.Toggle id="dropdown-basic" as="span">Sources</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Sources</Dropdown.Header>
                            <Dropdown.Divider />
                            <div className="sources-menu">
                                {dropdownItems}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {REGIONAL_ENABLED && <Region />}
            </div>
            <div className="resultContent" data-testid="resultContent">
                {result && result.ner && (!Array.isArray(result.ner) || result.ner.length > 0) &&
                    <ul className="list-group m-2">
                        <li className="list-group-item active">Not visible to end users - For internal team only</li>
                        {!Array.isArray(result.ner) && <li className="list-group-item" >{result.ner}</li>}
                        {Array.isArray(result.ner) && result.ner.map((ent: any, index: number) => (
                            <li className="list-group-item" key={index}>{ent.Text}: {
                                // @ts-ignore
                                ners[ent.Category] 
                            }</li>
                        ))}
                    </ul>
                }
                {content}
            </div>
        </div>
    );
}

export default Result;
