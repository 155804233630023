import React from 'react';

import {globalSources} from '../../utils/helpers';
import TagsBubble from '../TagsBubble/TagsBubble'
import {TAGS} from '../../utils/constants'

import './HelpPage.css';

type HelpPageProps = {
    tagClickHandler: Function;
    onClose: Function;
}

// eslint-disable-next-line react/prop-types
const HelpPage: React.FC<HelpPageProps> = ({tagClickHandler, onClose}) => {
    return (
        <div className="container pb-4">
            <div className="text-right">
                <button onClick={()=>onClose()} className="mobile-help-close"><i className="fa fa-times"></i></button>
            </div>
            <h6 className="mobile-help-title">WHAT CAN TALI DO?</h6>
            <h3 className="mobile-help-desc">Here is a guide into what types of questions you can ask Tali</h3>

            <div style={{marginBottom: '24px'}}>
                <TagsBubble content={TAGS} fullpage={true} clickHandler={(tag: string)=>{tagClickHandler(tag)}} />
            </div>
            
            <h6 className="mobile-help-title">WHERE DOES TALI GET IT’S INFORMATION?</h6>
            <div className="d-flex flex-row flex-nowrap mobile-help-box">
                {Object.keys(globalSources).map((key, i) => (
                    <div className="col-lg-4" key={i}>
                        <div className="d-flex align-items-center">
                            <img src={globalSources[key].logo} alt={globalSources[key].name} className="mobile-help-resource-image"/>
                            <p className="mobile-help-resource-title">{globalSources[key].name}</p>
                        </div>
                    </div>
                ))}
            </div>

            <h6 className="mobile-help-title">WHO IS THIS FOR?</h6>
            <p className="mobile-help-answer">Care providers, residents, and medical students who are searching for medically reviewed content.</p>

            <h6 className="mobile-help-title">IS TALI OFFICIAL?</h6>
            <p className="mobile-help-answer">No, and Tali is not responsible for providing personalized medical advice, diagnosis, or treatment. If you are experiencing a life threatening emergency, please call 911.</p>
            
            <h6 className="mobile-help-title">HOW DO I ENSURE GETTING MORE ACCURATE INFORMATION?</h6>
            <p className="mobile-help-answer">When asking questions about medicine try using generic names rather than brand names (ex. Ibuprofen instead of Tylenol). </p>
        </div>
    )
}

export default HelpPage;